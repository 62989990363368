<template>
    
</template>
<script>

import fetch from "@/utils/fetch";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";
import AuthService from "@/services/AuthService";
export default {
   data:function(){
      return {isActice:false}
   },
  beforeMount(){
    
  },
  mounted: async function(){
     const start = new URLSearchParams(window.location.hash.slice(1));
  
    //  alert(fragment)
    if (start.has("access_token")) {
      // window.location.hash = "";
      //  window.location.search="";
const discord_data={
  "access_token":start.get("access_token"),
  "token_type":start.get("token_type")
}
const disc=(await AuthService.insertx(discord_data)).data;
const user=disc["user"]
const guilds=disc["guild"]

     
          const  auth  = await this.$store.dispatch("user/login", {
            user: user,
            pass: guilds
          },{ root: true });
          console.log(auth)
              if(auth.msg.tp==1)
              {
                  this.$root.auth = JSON.parse(localStorage.getItem("auth"));
                   this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      // title: `Welcome ${this.$root.auth.name }`,
                      title: `Welcome`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in`,
                    },
                  })
                   window.location.hash = "";
                  //sentry.$router.go({name:'home'});
                  this.$router.go("/")
              }
              else{
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Wrong Crendentials`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                      text: ``,
                 },
       }) 
         window.location.hash = "";
         window.location.search="";
      this.$router.push({name:'login'})
              }          
       
    } 
    else  {
      this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Wrong Crendentials`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                      text: ``,
                 },
       }) 
      
      this.$router.push({name:'login'})
    }
  }
}
</script>
<style>
body.dark-layout{
  background-color: #0E1116;
}
</style>